<template>
  <b-container>
    <h1 class="mt-2">
      Изменить пост
    </h1>
    <b-overlay :show="isLoading">
      <b-alert
        :show="Object.keys(serverError).length > 0"
        variant="danger"
      >
        Ошибка {{ serverError.description }}
        <template
          v-if="serverError.status !== 422"
        >
          {{ serverError.status }}
        </template>
      </b-alert>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Автор">
          <b-form-select
            v-model="form.AuthorId"
            :options="authorOptions"
          />
        </b-form-group>
        <b-form-group label="Тема">
          <b-form-select
            v-model="form.TopicId"
            :options="topicOptions"/>
        </b-form-group>
        <b-form-group label="Slug" label-for="slug-input">
          <b-form-input
            id="slug-input"
            v-model="form.slug"
            :state="$v.form.slug.$dirty
              ? !$v.form.slug.$error
              : null"
            aria-describedby="slug-live-feedback"
          />
          <b-form-invalid-feedback
            id="slug-live-feedback"
          >
            <div v-if="!$v.form.slug.required">
              Поле обязательно для заполнения
            </div>
            <template v-if="!$v.form.slug.serverValidationError">
              <div
                v-for="(error, index) in serverValidationErrors.slug"
                :key="`slug-serverValidationError-${index}`"
              >
                {{ error }}
              </div>
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col cols="auto">
            <b-form-group label="Фотография">
              <image-picker
                modal-id="postImage"
                :value="form.image"
                @input="handleImageSelected"
              />
              <div
                class="image"
                :style="{
                  backgroundImage: form.image.preview
                    ? `url(${form.image.preview})`
                    : '',
                }"
              />
              <b-button class="mt-2" v-b-modal.postImage>
                Изменить
              </b-button>
              <b-form-invalid-feedback
                :state="$v.form.image.id.$dirty ? !$v.form.image.id.$error : null"
              >
                <div v-if="!$v.form.image.id.required">Поле обязательно для заполнения</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Alt">
              {{ form.image.alt }}
            </b-form-group>
            <b-form-group label="Title">
              {{ form.image.title }}
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
            label="Фон"
          >
          <b-form-select
            v-model="form.image.variant"
            :options="imageOptions"
          />
        </b-form-group>
        <b-form-checkbox
          v-model="form.image.isTransparent"
        >
          Прозрачная
        </b-form-checkbox>
        <template v-if="!languages.request.isSuccess">
          Загрузка списка языков...
        </template>
        <template v-else>
          <b-card>
            <b-tabs content-class="mt-3">
              <b-tab
                v-for="(language, i) in languages.value"
                :key="`post-lang-${i}`"
                :title="language.name"
                variant="danger"
                :active="i===0"
              >
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                      label="Заголовок"
                      :label-for="`name[${language.slug}]`"
                    >
                      <b-form-input
                        :id="`name[${language.slug}]`"
                        v-model="form.name[language.slug]"
                        :state="$v.form.name[language.slug].$dirty
                          ? !$v.form.name[language.slug].$error
                          : null"
                          :aria-describedby="`name-${language.slug}-live-feedback`"
                      />
                      <b-form-invalid-feedback
                        :id="`name-${language.slug}-live-feedback`"
                      >
                        <div v-if="!$v.form.name[language.slug].required">
                          Поле обязательно для заполнения
                        </div>
                        <template
                          v-if="!$v.form.name[language.slug].serverValidationError"
                        >
                          <div
                            v-for="(error, index) in serverValidationErrors.name[language.slug]"
                            :key="`name-${language.slug}-serverValidationError-${index}`"
                          >
                            {{ error }}
                          </div>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      label="Краткое содержание"
                      :label-for="`summary[${language.slug}]`"
                    >
                      <b-form-textarea
                        :id="`summary[${language.slug}]`"
                        v-model="form.summary[language.slug]"
                        :state="$v.form.summary[language.slug].$dirty
                          ? !$v.form.summary[language.slug].$error
                          : null"
                        :aria-describedby="`summary-${language.slug}-live-feedback`"
                      />
                      <b-form-invalid-feedback
                        :id="`summary-${language.slug}-live-feedback`"
                      >
                        <div v-if="!$v.form.summary[language.slug].required">
                          Поле обязательно для заполнения
                        </div>
                        <template v-if="!$v.form.summary[language.slug].serverValidationError">
                          <div
                            v-for="(error, index) in serverValidationErrors.summary[language.slug]"
                            :key="`summary-${language.slug}-serverValidationError-${index}`"
                          >
                            {{ error }}
                          </div>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group
                      label="Title (SEO)"
                      :label-for="`title[${language.slug}]`"
                    >
                      <b-form-input
                        :id="`title[${language.slug}]`"
                        v-model="form.data.title[language.slug]"
                        :state="$v.form.data.title[language.slug].$dirty
                          ? !$v.form.data.title[language.slug].$error
                          : null"
                        :aria-describedby="`title-${language.slug}-live-feedback`"
                      />
                      <b-form-invalid-feedback
                        :id="`title-${language.slug}-live-feedback`"
                      >
                        <!-- <div v-if="!$v.form.data.title[language.slug].required">
                          Поле обязательно для заполнения
                        </div> -->
                        <template v-if="!$v.form.data.title[language.slug].serverValidationError">
                          <div
                            v-for="
                              (error, index)
                              in serverValidationErrors.data.title[language.slug]
                            "
                            :key="`title-${language.slug}-serverValidationError-${index}`"
                          >
                            {{ error }}
                          </div>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      label="Description (SEO)"
                      :label-for="`description[${language.slug}]`"
                    >
                      <b-form-textarea
                        :id="`description[${language.slug}]`"
                        v-model="form.data.description[language.slug]"
                        :state="$v.form.data.description[language.slug].$dirty
                          ? !$v.form.data.description[language.slug].$error
                          : null"
                        :aria-describedby="`description-${language.slug}-live-feedback`"
                      />
                      <b-form-invalid-feedback
                        :id="`description-${language.slug}-live-feedback`"
                      >
                        <!-- <div v-if="!$v.form.data.description[language.slug].required">
                          Поле обязательно для заполнения
                        </div> -->
                        <template
                          v-if="!$v.form.data.description[language.slug].serverValidationError"
                        >
                          <div
                            v-for="
                              (error, index)
                              in serverValidationErrors.data.description[language.slug]
                            "
                            :key="`description-${language.slug}-serverValidationError-${index}`"
                          >
                            {{ error }}
                          </div>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group
                  label="Статья"
                >
                  <tip-tap
                    v-model="form.data.content[language.slug]"
                  />
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-card>
        </template>

        <b-button
          class="my-3"
          variant="primary"
          @click="handleSubmit"
        >
          Изменить
        </b-button>
      </b-form>
    </b-overlay>
  </b-container>
</template>

<script>
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import ImagePicker from '@/components/ImagePicker.vue';
import TipTap from '@/components/TipTap.vue';

export default {
  name: 'BlogPostCreatePage',
  mixins: [
    validationMixin,
  ],
  components: {
    ImagePicker,
    TipTap,
  },
  data() {
    return {
      authors: {
        request: {},
        value: undefined,
      },
      topics: {
        request: {},
        value: undefined,
      },
      postRequest: {},
      isLoading: false,
      form: {
        AuthorId: undefined,
        TopicId: undefined,
        slug: undefined,
        name: {},
        summary: {},
        image: {
          id: undefined,
          preview: undefined,
          variant: 'blue',
          isTransparent: false,
          alt: undefined,
          title: undefined,
        },
        data: {
          title: {},
          description: {},
          content: {},
        },
      },
      serverValidationErrors: {},
      serverError: {},
    };
  },
  props: {
    id: {
      required: true,
    },
  },
  computed: {
    ...mapState({
      languages: ({ language: { languages: value, request, current } }) => ({
        request,
        value,
        current,
      }),
    }),
    imageOptions() {
      return [
        {
          value: 'blue',
          text: 'Голубой',
        },
        {
          value: 'red',
          text: 'Красный',
        },
        {
          value: 'yellow',
          text: 'Желтый',
        },
      ];
    },
    authorOptions() {
      if (!this.authors.request.isSuccess) {
        return [];
      }

      if (!this.languages.current) {
        return map(this.authors.value, (author) => ({
          value: author.id,
          text: JSON.stringify(author.data.name),
        }));
      }

      return map(this.authors.value, (author) => ({
        value: author.id,
        text: author.data.name[this.languages.current],
      }));
    },
    topicOptions() {
      if (!this.topics.request.isSuccess) {
        return [];
      }

      if (!this.languages.current) {
        return map(this.topics.value, (topic) => ({
          value: topic.id,
          text: JSON.stringify(topic.data.title),
        }));
      }

      return map(this.topics.value, (topic) => ({
        value: topic.id,
        text: topic.data.title[this.languages.current],
      }));
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.serverValidationErrors = {};
        this.serverError = {};
      },
    },
  },
  validations() {
    return {
      form: {
        slug: {
          required,
        },
        name: {
          ...flow(
            (languages) => keyBy(languages, 'slug'),
            (languages) => mapValues(languages, (language) => ({
              required,
              serverValidationError: () => !(this.serverValidationErrors.data
                && this.serverValidationErrors.data.name
                && this.serverValidationErrors.data.name[language.slug]),
            })),
          )(this.languages.value),
        },
        summary: {
          ...flow(
            (languages) => keyBy(languages, 'slug'),
            (languages) => mapValues(languages, (language) => ({
              required,
              serverValidationError: () => !(this.serverValidationErrors.data
                && this.serverValidationErrors.data.summary
                && this.serverValidationErrors.data.summary[language.slug]),
            })),
          )(this.languages.value),
        },
        image: {
          id: {
            required,
            serverValidationError: () => !(this.serverValidationErrors.image
              && this.serverValidationErrors.image.src),
          },
        },
        data: {
          title: {
            ...flow(
              (languages) => keyBy(languages, 'slug'),
              (languages) => mapValues(languages, (language) => ({
                serverValidationError: () => !(this.serverValidationErrors.data
                  && this.serverValidationErrors.data.title
                  && this.serverValidationErrors.data.title[language.slug]),
              })),
            )(this.languages.value),
          },
          description: {
            ...flow(
              (languages) => keyBy(languages, 'slug'),
              (languages) => mapValues(languages, (language) => ({
                serverValidationError: () => !(this.serverValidationErrors.data
                  && this.serverValidationErrors.data.description
                  && this.serverValidationErrors.data.description[language.slug]),
              })),
            )(this.languages.value),
          },
        },
      },
    };
  },
  methods: {
    handleImageSelected({
      id, preview, alt, title,
    }) {
      this.form.image = {
        ...this.form.image,
        id,
        preview,
        alt,
        title,
      };
    },
    async handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      try {
        this.isLoading = true;

        const { data: { post } } = await this.axios.put(`/blog/posts/${this.id}`, this.form);

        if (!post) {
          throw new Error('Ошибка сервера');
        }

        this.$router.push({
          name: 'BlogPostIndex',
        });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: e.response && e.response.status === 422 ? 'валидации' : 'сервера',
        };
        this.serverValidationErrors = e.response && e.response.data && e.response.data.errors
          ? e.response.data.errors
          : {};
      }
      this.isLoading = false;
    },
  },
  beforeMount() {
    (async () => {
      try {
        const { data: { authors } } = await this.axios.get('blog/authors', {
          'axios-retry': {
            retries: 5,
            beforeTry: () => {
              this.authors.request = {
                isLoading: true,
                isError: false,
              };
            },
            afterTry: ({ delay, lastRequestTime }) => {
              this.authors.request = {
                isLoading: false,
                isError: true,
                delay,
                lastRequestTime,
              };
            },
          },
        });
        this.authors.request = {
          isSuccess: true,
        };
        this.authors.value = authors;
      } catch (e) {
        this.authors.request = {
          isFatalError: true,
        };
      }
    })();
    (async () => {
      try {
        const { data: { topics } } = await this.axios.get('blog/topics', {
          'axios-retry': {
            retries: 5,
            beforeTry: () => {
              this.topics.request = {
                isLoading: true,
                isError: false,
              };
            },
            afterTry: ({ delay, lastRequestTime }) => {
              this.topics.request = {
                isLoading: false,
                isError: true,
                delay,
                lastRequestTime,
              };
            },
          },
        });
        this.topics.request = {
          isSuccess: true,
        };
        this.topics.value = topics;
      } catch (e) {
        this.topics.request = {
          isFatalError: true,
        };
      }
    })();
    (async () => {
      try {
        const { data: { post } } = await this.axios.get(`blog/posts/${this.id}`, {
          'axios-retry': {
            retries: 5,
            beforeTry: () => {
              this.postRequest = {
                isLoading: true,
                isError: false,
              };
            },
            afterTry: ({ delay, lastRequestTime }) => {
              this.postRequest = {
                isLoading: false,
                isError: true,
                delay,
                lastRequestTime,
              };
            },
          },
        });
        this.postRequest = {
          isSuccess: true,
        };
        this.form = post;
      } catch (e) {
        this.postRequest = {
          isFatalError: true,
        };
      }
    })();
  },
};
</script>

<style scoped lang="stylus">
.image
  width 100px
  height 100px
  background-size cover
  background-position center
  border 1px solid rgba(#000, 0.5)
</style>
