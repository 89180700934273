var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('h1',{staticClass:"mt-2"},[_vm._v(" Изменить пост ")]),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-alert',{attrs:{"show":Object.keys(_vm.serverError).length > 0,"variant":"danger"}},[_vm._v(" Ошибка "+_vm._s(_vm.serverError.description)+" "),(_vm.serverError.status !== 422)?[_vm._v(" "+_vm._s(_vm.serverError.status)+" ")]:_vm._e()],2),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Автор"}},[_c('b-form-select',{attrs:{"options":_vm.authorOptions},model:{value:(_vm.form.AuthorId),callback:function ($$v) {_vm.$set(_vm.form, "AuthorId", $$v)},expression:"form.AuthorId"}})],1),_c('b-form-group',{attrs:{"label":"Тема"}},[_c('b-form-select',{attrs:{"options":_vm.topicOptions},model:{value:(_vm.form.TopicId),callback:function ($$v) {_vm.$set(_vm.form, "TopicId", $$v)},expression:"form.TopicId"}})],1),_c('b-form-group',{attrs:{"label":"Slug","label-for":"slug-input"}},[_c('b-form-input',{attrs:{"id":"slug-input","state":_vm.$v.form.slug.$dirty
            ? !_vm.$v.form.slug.$error
            : null,"aria-describedby":"slug-live-feedback"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),_c('b-form-invalid-feedback',{attrs:{"id":"slug-live-feedback"}},[(!_vm.$v.form.slug.required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.slug.serverValidationError)?_vm._l((_vm.serverValidationErrors.slug),function(error,index){return _c('div',{key:("slug-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label":"Фотография"}},[_c('image-picker',{attrs:{"modal-id":"postImage","value":_vm.form.image},on:{"input":_vm.handleImageSelected}}),_c('div',{staticClass:"image",style:({
                backgroundImage: _vm.form.image.preview
                  ? ("url(" + (_vm.form.image.preview) + ")")
                  : '',
              })}),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.postImage",modifiers:{"postImage":true}}],staticClass:"mt-2"},[_vm._v(" Изменить ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.image.id.$dirty ? !_vm.$v.form.image.id.$error : null}},[(!_vm.$v.form.image.id.required)?_c('div',[_vm._v("Поле обязательно для заполнения")]):_vm._e()])],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Alt"}},[_vm._v(" "+_vm._s(_vm.form.image.alt)+" ")]),_c('b-form-group',{attrs:{"label":"Title"}},[_vm._v(" "+_vm._s(_vm.form.image.title)+" ")])],1)],1),_c('b-form-group',{attrs:{"label":"Фон"}},[_c('b-form-select',{attrs:{"options":_vm.imageOptions},model:{value:(_vm.form.image.variant),callback:function ($$v) {_vm.$set(_vm.form.image, "variant", $$v)},expression:"form.image.variant"}})],1),_c('b-form-checkbox',{model:{value:(_vm.form.image.isTransparent),callback:function ($$v) {_vm.$set(_vm.form.image, "isTransparent", $$v)},expression:"form.image.isTransparent"}},[_vm._v(" Прозрачная ")]),(!_vm.languages.request.isSuccess)?[_vm._v(" Загрузка списка языков... ")]:[_c('b-card',[_c('b-tabs',{attrs:{"content-class":"mt-3"}},_vm._l((_vm.languages.value),function(language,i){return _c('b-tab',{key:("post-lang-" + i),attrs:{"title":language.name,"variant":"danger","active":i===0}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Заголовок","label-for":("name[" + (language.slug) + "]")}},[_c('b-form-input',{attrs:{"id":("name[" + (language.slug) + "]"),"state":_vm.$v.form.name[language.slug].$dirty
                        ? !_vm.$v.form.name[language.slug].$error
                        : null,"aria-describedby":("name-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.name[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.name, language.slug, $$v)},expression:"form.name[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("name-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.name[language.slug].required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.name[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.name[language.slug]),function(error,index){return _c('div',{key:("name-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1),_c('b-form-group',{attrs:{"label":"Краткое содержание","label-for":("summary[" + (language.slug) + "]")}},[_c('b-form-textarea',{attrs:{"id":("summary[" + (language.slug) + "]"),"state":_vm.$v.form.summary[language.slug].$dirty
                        ? !_vm.$v.form.summary[language.slug].$error
                        : null,"aria-describedby":("summary-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.summary[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.summary, language.slug, $$v)},expression:"form.summary[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("summary-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.summary[language.slug].required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.summary[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.summary[language.slug]),function(error,index){return _c('div',{key:("summary-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Title (SEO)","label-for":("title[" + (language.slug) + "]")}},[_c('b-form-input',{attrs:{"id":("title[" + (language.slug) + "]"),"state":_vm.$v.form.data.title[language.slug].$dirty
                        ? !_vm.$v.form.data.title[language.slug].$error
                        : null,"aria-describedby":("title-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.data.title[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.data.title, language.slug, $$v)},expression:"form.data.title[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("title-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.data.title[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.data.title[language.slug]),function(error,index){return _c('div',{key:("title-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1),_c('b-form-group',{attrs:{"label":"Description (SEO)","label-for":("description[" + (language.slug) + "]")}},[_c('b-form-textarea',{attrs:{"id":("description[" + (language.slug) + "]"),"state":_vm.$v.form.data.description[language.slug].$dirty
                        ? !_vm.$v.form.data.description[language.slug].$error
                        : null,"aria-describedby":("description-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.data.description[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.data.description, language.slug, $$v)},expression:"form.data.description[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("description-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.data.description[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.data.description[language.slug]),function(error,index){return _c('div',{key:("description-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1)],1)],1),_c('b-form-group',{attrs:{"label":"Статья"}},[_c('tip-tap',{model:{value:(_vm.form.data.content[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.data.content, language.slug, $$v)},expression:"form.data.content[language.slug]"}})],1)],1)}),1)],1)],_c('b-button',{staticClass:"my-3",attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Изменить ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }